<template>
  <div class="centerwrap">
    <div class="centerbox">
      <div class="topbox">
        <h2 class="titleh2" v-text="content.Article" v-if="isEditor==false"></h2>
        <input v-model="content.Article" v-else class="editinp" placeholder="无标题" ref="editinp" v-focus />
        <el-button
          v-if="isEditor==true"
          type="primary"
          plain
          size="mini"
          class="savebtn"
          @click="saveBook"
        >保 存</el-button>
        <span class="iconbox" v-if="isEditor==false">
          <i class="iconfont iconshanchu3"  @click="handelDelBook(content.Id)"></i>
          <i>
            <el-popover placement="bottom" title="版权" width="200" trigger="hover" content="归作者所有">
              <i slot="reference" class="iconfont icontishi"></i>
            </el-popover>
          </i>
          <!-- <i class="iconfont iconbianzu6"></i>
          <i class="iconfont iconxingzhuang4"></i> -->
          <i class="iconfont iconbianji2" @click="showEditor" :class="isEditor==true&&'active'"></i>
        </span>
        <span class="iconbox" v-else>
          <i
            @click="handelChangeMavon('Editor')"
            class="iconfont iconqiehuan"
          >切换到 {{Editor==1? 'markdown' :'tinymce'}}</i>
          <i class="iconfont iconbianzu5" @click="showEditor">查看</i>
        </span>
      </div>
      <div class="textbox" v-if="isEditor==false" v-html="Content"></div>
      <div class="editor" v-else>
        <tinymce-editor
          v-model="Content"
          :disabled="disabled"
          ref="editor"
          :theight="curHeight"
          v-if="Editor==1"
        ></tinymce-editor>
        <mavon-editor
          :value="Markdown"
          ref="meditor"
          @input="Markdown=$event"
          v-else
          :theight="curHeight"
        ></mavon-editor>
      </div>
    </div>
  </div>
</template>
<script>
import {
  booksSectionRequest,
  booksArticleRequest,
  notesArtcileRequest,
  saveNotesArtcileRequest,
  notesOptRequest
} from "@/api/project.js";
import FoldTop from "@/components/Foldtop/foldTop";
import TinymceEditor from "@/components/tinymceEditor"; //编辑器
import MavonEditor from "@/components/mavonEditor"; //编辑器
import { TurndownService } from "@/assets/js/turndown.js";

import Prism from "prismjs";
import "prismjs/themes/prism-coy.css";

export default {
  components: {
    FoldTop,
    TinymceEditor, //编辑器
    MavonEditor //编辑器
  },
  data() {
    return {
      content: {
        Article: ""
      },
      Content: "",
      isEditor: false,
      disabled: false,
      inittac: {
        height: "500px"
      },
      Editor: 1, //文章默认编辑器
      editorIndex: 1, //整本书默认编辑器
      Markdown: "",
      curHeight: 0
    };
  },
  directives: {
    //自定义的v-focus指令
    focus: {
      inserted: function(el) {
        el.focus();
      }
    }
  },
  created() {
    // this.getBookSection(this.Id);
    // this.editorIndex=this.$store.state.editorIndex;
    this.setEditorHeight();
    if (this.artid) {
      this.getBookSection();
    } else {
      this.isEditor = true;
    }
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
    async getBookSection() {
      try {
        var _this = this;
        const result = await notesArtcileRequest({
          artid: this.artid
        });
        if (result.status == 200) {
          if (result.data) {
            this.content = result.data.article;
            this.Content = result.data.article.Content;
            this.Editor = result.data.article.Editor;
            this.Markdown = result.data.article.Markdown || "";
          }
          setTimeout(() => {
            Prism.highlightAll();
          }, 100);
        }
      } catch (err) {}
    },
    handelTab(index, a) {
      this[a] = index;
    },
    showEditor() {
      this.isEditor = !this.isEditor;
      if (this.isEditor == false && this.Editor == 2) {
        this.TmacChangeMavon(1);
      }
      this.Editor = this.Editor === 0 ? this.editorIndex : this.Editor;
      setTimeout(() => {
        Prism.highlightAll();
      }, 100);
    },
    TmacChangeMavon(editor, item) {
      if (item) this[item] = editor;
      // editor=2 是  markdown  既markdown 变成 tinymac格式
      // editor=1 是  tinymce   tinymac 变成 markdown
      var _this = this;
      if (editor === 1) {
        // 既markdown 变成 tinymac格式
        var marked = require("marked");
        var rendererMD = new marked.Renderer();
        marked.setOptions({
          renderer: rendererMD,
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }); //基本设置
        _this.Content = marked(_this.Markdown);
      } else {
        //tinymac 变成 markdown
        var turndownService = new TurndownService(); //Tmac变成Mavon格式
        _this.Markdown = turndownService.turndown(_this.Content);
      }
    },
    handelChangeMavon(Editor) {
      var _this = this;
      this.$confirm("切换编辑器后，部分样式可能会丢失, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.TmacChangeMavon(_this[Editor] === 2 ? 1 : 2, Editor);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消切换"
          });
        });
    },
    async saveBook() {
      try {
        var oData = {};
        if (this.Editor === 2) {
          //markdown
          this.TmacChangeMavon(1);
        }
        //markdown
        oData = {
          artid: this.artid,
          article: this.content.Article || '无标题',
          content: this.Content,
          editor: this.Editor,
          markdown: this.Editor === 2 ? this.Markdown : ""
        };
        const result = await saveNotesArtcileRequest(oData);
        if(result.status==200){
          this.$message({
            showClose: true,
            message: result.message,
            type: "success"
          });
          this.$store.commit("setAsideNotesChange",oData);
          this.$router.push({name:'notesDetail',query:{artid:result.data.artid}})
        }
        else{
          this.$message({
            showClose: true,
            message: result.message,
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
      }
      console.log("ok");
    },
    setEditorHeight() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.curHeight = h - 61; //减去页面上固定高度height
      console.log(this.curHeight);
    },
    async specialArticleDel(id) {
      try {
        const result = await notesOptRequest({
          noteid: id
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          var oData={
            artid: this.artid,
            del:true
          }
          this.$store.commit("setAsideNotesChange",oData);
          this.$router.push({
            name: "notes"
          });
        } else {
          this.$message({
            type: "error",
            message: result.message
          });
        }
      } catch (err) {}
    },
    handelDelBook(id) {
      var _this = this;
      this.$confirm("此操作将永久删除该笔记, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.specialArticleDel(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  },
  computed: {
    artid() {
      return this.$route.query.artid || 0;
    }
  },
  watch: {
    Id: {
      handler(newName, oldName) {
        if (newName && newName !== oldName) {
          this.getBookSection(newName);
        }
      },
      immediate: true
    },
    $route(newName,oldName){
      if(newName.query.artid){
         this.getBookSection()
      }
      else{
        this.content.Article= ""
        this.Content= ""
        this.Markdown= ""
         this.isEditor = true;
         this.Editor = 1;
         this.editorIndex = 1;
         
      }
      
    }
  }
};
</script>
<style  lang="less" scoped>
.textbox{
    /deep/p{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  /deep/ul,  /deep/li  {
  list-style: inherit;
}
/deep/h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}
/deep/em, /deep/i {
font-style:italic!important;
}
/deep/ol{
  display: block;
      list-style: decimal;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px
}
}
.centerwrap {
  position: relative;
  margin: -15px;
}
.topbox {
  border-bottom: 1px solid #ccc;
  line-height: 40px;
  padding: 10px 20px 10px 50px;
  height: 40px;
  display: flex;
  .titleh2 {
    font-size: 20px;
    flex: 1;
  }
  .iconbox {
    width: 250px;
    & > i {
      float: right;
      margin-left: 15px;
      color: #11adcf;
      font-size: 14px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      &.active {
        color: #3385ff;
      }
    }
  }
}
.textbox {
  padding: 20px;
  .textp {
    font-size: 14px;
    line-height: 24px;
    text-indent: 2em;
    margin-bottom: 10px;
  }
}
/deep/.collapse {
  i {
    color: #aaa;
  }
}
/deep/.tox-tinymce {
  border-left: none;
  border-right: none;
  border-top: none;
}
.changeedit {
  cursor: pointer;
  text-align: right;
  margin: 20px;
  i {
    color: #11adcf;
  }
}
.savebtn {
  margin-right: -40px;
  height: 30px;
  margin-top: 6px;
  position: relative;
  z-index: 10;
}
/deep/.mavon-editor {
  .v-note-wrapper.shadow {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
  }
}
/deep/.editinp{
  flex: 1;
    border: none;
    background: none;
    font-size: 20px;
  
}
</style>
